import Activity from '@/components/activity/common-activity';
import { AntdThemeConfigProvider } from '@/components/antd-config-provider/theme-config-provider';
import { Cookie } from '@/components/cookie';
import { DesktopOrTablet } from '@/components/responsive';
import '@/core/prototype';
import { AppProvider } from '@/core/store';
import '@/core/styles/src/design.scss';
import { GlobalStyle } from '@/core/styles/src/global-style';
import { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { useEffect } from 'react';
const ErrorBoundary = dynamic(() => import('@/components/error/error-boundary'));

const RootApp = ({ Component, pageProps }: AppProps) => {
  useEffect(() => {
    console.info('%c%s', 'font-weight: bold;color: #5f55a5', `Release info: ${process.env.NEXT_PUBLIC_BUILD_TIME}`);
  }, []);
  return (
    <AppProvider auth={pageProps.auth}>
      <GlobalStyle>
        <ErrorBoundary>
          <AntdThemeConfigProvider>
            <Component {...pageProps} />
            <DesktopOrTablet>
              <Activity />
            </DesktopOrTablet>
            <Cookie />
          </AntdThemeConfigProvider>
        </ErrorBoundary>
      </GlobalStyle>
    </AppProvider>
  );
};

export default RootApp;
